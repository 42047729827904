import Firestore from "models/Firestore";
import Event from "models/Event";
import UserCourse from "models/UserCourse";
import Backend from "utils/Backend";

import randomstring from "randomstring";
import { USER_STATUSES } from "constants";
import UAParser from "ua-parser-js";

const COLLECTION_NAME = "users";

class User extends Firestore {
  constructor(authContext) {
    super(COLLECTION_NAME, authContext);
  }

  /**
   * Create a user.
   * Returns Object { uid }
   */
  async create({ email, password, brand_id, first_name, last_name, role }) {
    const response = await Backend.post("users/create", {
      email,
      brand_id,
      first_name,
      last_name,
      role,
      password
    });

    return response.data;
  }

  /**
   * Create a user for an integration.
   * Returns Object { uid }
   */
  async createIntegration({ name, brand_id, config }) {
    const response = await Backend.post("users/create_integration", {
      name,
      brand_id,
      api_key: randomstring.generate(8),
      config
    });

    return response.data;
  }

  async update(id, doc, skipEvent) {
    if (!skipEvent) {
      Event.create({
        model_owner: "user",
        model_id: id,
        event_category: "document",
        event_name: "update",
        metadata: doc
      });
    }
    await super.update(id, doc);
  }

  /**
   * Merge two user profiles into one
   * original_uid = user id of original user that is being viewed
   * selected_uid = user id of selected user to merge with the original user
   */
  async merge(original_uid, selected_uid) {
    // move all user courses of old user to original user
    const userCourse = new UserCourse();
    await userCourse.moveUserCourses(original_uid, selected_uid);
    // add a merge_with_uid field to identify that old user is already merged with other user
    // merged user get set to archived
    await this.update(selected_uid, {
      merge_with_uid: original_uid,
      status: USER_STATUSES.ARCHIVED
    });

    // move all events of old user to original user
    await Event.mergeUserEvents(original_uid, selected_uid);

    const events = [
      // event for old user notifying that this account has been moved
      {
        model_owner: "user",
        model_id: selected_uid,
        event_category: "account_merged",
        event_name: "merge",
        metadata: { original_uid }
      },
      // event for main user that the old user was merged in
      {
        model_owner: "user",
        model_id: original_uid,
        event_category: "old_user_merged_in",
        event_name: "merge",
        metadata: { selected_uid }
      }
    ];
    await Event.create(events);
  }

  async delete(uid) {
    return Backend.post("users/delete_customer", {
      uid
    });
  }

  async resetPassword(uid, new_password) {
    return Backend.post("users/reset_password", {
      uid,
      new_password
    });
  }

  async cancelSubscription(subscriptionId, cancellationReason, userId) {
    const result = await Backend.post("integrations/seal_subscription/cancel", {
      subscriptionId,
      cancellationReason,
      userId
    });
    return result;
  }

  async reactivateSubscription(subscriptionId, userId) {
    const result = await Backend.post(
      "integrations/seal_subscription/reactivate",
      {
        subscriptionId,
        userId
      }
    );
    return result;
  }

  static async recordAuthActivity(uid, event_category, event_name) {
    const parser = new UAParser();
    const { browser, device } = parser.getResult();
    Event.create({
      model_owner: "user",
      model_id: uid,
      event_category,
      event_name,
      metadata: {
        browser,
        device
      }
    });
  }

  async unsubscribeMarketingEmails(uid) {
    return Backend.post("integrations/klaviyo/unsubscribe", {
      uid
    });
  }

  async getIntegrationUserByBrandAndName(partnerId, brandId, name) {
    const integrationUserRef = await this.colRef()
      .where("role", "==", "integration")
      .where("partner_id", "==", partnerId)
      .where("brand_id", "==", brandId)
      .where("name", "==", name)
      .get();
    if (integrationUserRef.docs.length) {
      const [integrationUser] = integrationUserRef.docs;
      const integrationUserRecord = {
        id: integrationUser.id,
        ...integrationUser.data()
      };
      return integrationUserRecord;
    }
    return null;
  }
}

export default User;
